var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('div',{staticClass:"mr-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'kindofsports-page', params: { id: 'new' } })}}},[_vm._v(" Новый вид спорта ")])],1),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Поиск")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):_vm._e(),(props.column.field === 'isDefault')?_c('span',[_c('b-form-checkbox',{on:{"change":function($event){return _vm.onDefaultChange(props.row)}},model:{value:(props.row.isDefault),callback:function ($$v) {_vm.$set(props.row, "isDefault", $$v)},expression:"props.row.isDefault"}})],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('router-link',{staticClass:"edit-button",attrs:{"to":{
                  name: 'kindofsports-page',
                  params: { id: props.row.id },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Редактировать")])],1),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.clubToDelete = props.row.id}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Удалить")])],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('infinite-loading',{attrs:{"spinner":"bubbles"},on:{"infinite":_vm.infiniteScroll}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],1),_c('div',[_c('div',{staticClass:"demo-inline-spacing"}),_c('b-modal',{attrs:{"id":"modal-primary","ok-only":"","ok-title":"Отмена","modal-class":"modal-primary","centered":"","title":"Primary Modal"},model:{value:(_vm.modalIsOpen),callback:function ($$v) {_vm.modalIsOpen=$$v},expression:"modalIsOpen"}},[_c('div',[_vm._v("Вы действительно хотите удалить вид спорта?")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.deleteItem(_vm.clubToDelete)}}},[_vm._v(" Удалить ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }